<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Modern -->
<modern-layout *ngIf="layout !== 'empty'"></modern-layout>

<yukawa-console-log-alerts *ngIf="displayErrorAlerts"></yukawa-console-log-alerts>
