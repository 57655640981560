import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Recipe } from '@nunc/lib/domain';
import { ConfigService } from '@yukawa/chain-base-angular-client';
import { delay } from 'rxjs/operators';
import { getRequestCallSign, LogEntry, logEntry$, NuncLibAuthService } from './nunc-lib.auth.service';


export interface Message
{
    fromName: string;
    subject: string;
    date: string;
    id: number;
    read: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class DeviceService
{

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService,
        private authService: NuncLibAuthService)
    {
    }

    public messages: Message[] = [
        {
            fromName: 'Organic Coffee',
            subject : 'Yeti Village...',
            date    : '9:32 AM',
            id      : 0,
            read    : false,
        },
        {
            fromName: 'Coffee Guji',
            subject : 'Highland...',
            date    : '6:12 AM',
            id      : 1,
            read    : false,
        },
        {
            fromName: 'Coffee Kenyana',
            subject : 'Nairobi Allstars',
            date    : '4:55 AM',
            id      : 2,
            read    : false,
        },
        {
            fromName: 'Lot Coffees',
            subject : 'Champions',
            date    : 'Yesterday',
            id      : 3,
            read    : false,
        },
        {
            fromName: 'Coffee Guevara',
            subject : 'Geisha Washed',
            date    : 'Yesterday',
            id      : 4,
            read    : false,
        },
        {
            fromName: 'Coffee Volcan',
            subject : 'Azul Sl 28...',
            date    : 'Yesterday',
            id      : 5,
            read    : false,
        },
        {
            fromName: 'Coffee',
            subject : 'The Forest',
            date    : 'Last Week',
            id      : 6,
            read    : false,
        },
        {
            fromName: 'Coffee Organic',
            subject : 'Bob-O-Link',
            date    : 'Last Week',
            id      : 7,
            read    : false,
        },
    ];
    private mSelectedBlend?: string;

    public getMessages(): Message[]
    {
        return this.messages;
    }

    public getMessageById(id: number): Message
    {
        return this.messages[id];
    }

    public getRecipe(refreshToken: any, payload: any, log: LogEntry, fakeDelay?: number): Promise<any>
    {

        return this.postRequest('POST(GetRecipe)', refreshToken, this.recipeUrl, payload, log, fakeDelay);
    }

    public updateRecipe(refreshToken: any, payload: any, log?: LogEntry, fakeDelay?: number): Promise<any>
    {
        const updateRecipeUrl = this.configService.formatUrl('recipeUpdateUrl');
        return this.postRequest(
            'POST(UpdateRecipe)',
            '', // interceptor's responsibility
            updateRecipeUrl,
            payload,
            undefined,
            fakeDelay,
        );
    }

    public addRecipe(refreshToken: any, payload: any, log?: LogEntry, fakeDelay?: number): Promise<any>
    {
        const addRecipeUrl = this.configService.formatUrl('recipeAddUrl');
        return this.postRequest(
            'POST(AddRecipe)',
            '', // interceptor's responsibility
            addRecipeUrl,
            payload,
            undefined,
            fakeDelay,
        );
    }

    public deleteRecipe(refreshToken: any, payload: any, log?: LogEntry, fakeDelay?: number): Promise<any>
    {
        const deleteRecipeUrl = this.configService.formatUrl('recipeDeleteUrl');
        return this.deleteRequest(
            'DELETE(DeleteRecipe)',
            '', // interceptor's responsibility
            deleteRecipeUrl,
            payload,
            undefined,
            fakeDelay,
        );
    }

    public deleteByKeyRecipe(refreshToken: any, payload: any, log?: LogEntry, fakeDelay?: number): Promise<any>
    {
        const deleteRecipeUrl =
                  this.configService.formatUrl('recipeDeleteByKeyUrl').concat(payload);
        return this.deleteRequest(
            'DELETE(DeleteRecipeByKey)',
            '', // interceptor's responsibility
            deleteRecipeUrl,
            payload,
            undefined,
            fakeDelay,
        );
    }

    public queryRecipe(refreshToken: any, payload: any, fakeDelay?: number): Promise<any>
    {
        // Admin Service - Recipe REST
        const queryRecipeUrl = this.configService.formatUrl('recipeQueryUrl');
        return this.postRequest(
            'POST(QueryRecipe)',
            '', // interceptor's responsibility
            queryRecipeUrl,
            payload,
            undefined,
            fakeDelay,
        );
    }

    public updateUser(payload: any, log?: LogEntry, fakeDelay?: number)
    {
        const updateUrl = this.configService.formatUrl('userUpdateUrl');
        return this.putRequest(
            'PUT(UpdateUser)',
            '', // interceptor's responsibility
            updateUrl,
            payload,
            undefined,
            fakeDelay,
        );
    }

    public addUser(payload: any, log?: LogEntry, fakeDelay?: number)
    {
        const addUrl = this.configService.formatUrl('userAddUrl');
        return this.postRequest(
            'POST(AddUser)',
            '', // interceptor's responsibility
            addUrl,
            payload,
            undefined,
            fakeDelay,
        );
    }

    public deleteUser(payload: any, log?: LogEntry, fakeDelay?: number)
    {
        const deleteUrl = `${this.configService.formatUrl('userDeleteUrl')}${payload}`;
        return this.deleteRequest(
            'DELETE(User)',
            '', // interceptor's responsibility
            deleteUrl,
            undefined,
            undefined,
            fakeDelay,
        );
    }

    public get selectedBlend(): string | undefined
    {
        return this.mSelectedBlend;
    }

    public set selectedBlend(selectedBlend: string | undefined)
    {
        this.mSelectedBlend = selectedBlend;
    }

    postRequest(actionPlus: string, token: string | any, url: string, payload: any, log?: LogEntry, fakeDelay = 0)
    {
        const postHeaderOptions = !!token ? { headers: this.getHeaders(token) } : undefined;
        return this.httpClient.post(url, payload, postHeaderOptions)
            .pipe(delay(fakeDelay))
            .toPromise()
            .then((successLogEntry) =>
            {
                if (!!log) {
                    log.loggedIn = this.authService.isLoggedIn();
                    log.request  = getRequestCallSign(`${actionPlus} ${url}`);
                    log.payload  = payload;
                    log.response = successLogEntry;
                    logEntry$.next(log);
                    return log;
                }
                else {
                    return successLogEntry;
                }
            }, (errorLogEntry) =>
            {
                if (!!log) {
                    log.loggedIn = this.authService.isLoggedIn();
                    log.request  = getRequestCallSign(`${actionPlus} ${url}`);
                    log.payload  = payload;
                    log.error    = errorLogEntry;
                    logEntry$.next(log);
                    return log;
                }
                else {
                    return errorLogEntry;
                }
            });
    }

    putRequest(actionPlus: string, token: string, url: string, payload: any, log?: LogEntry, fakeDelay = 0)
    {
        return this.httpClient.put(url, payload, { headers: this.getHeaders(token) })
            .pipe(delay(fakeDelay))
            .toPromise()
            .then((successLogEntry) =>
            {
                if (!!log) {
                    log.loggedIn = this.authService.isLoggedIn();
                    log.request  = getRequestCallSign(`${actionPlus} ${url}`);
                    log.payload  = payload;
                    log.response = successLogEntry;
                    logEntry$.next(log);
                    return log;
                }
                else {
                    return successLogEntry;
                }
            }, (errorLogEntry) =>
            {
                if (!!log) {
                    log.loggedIn = this.authService.isLoggedIn();
                    log.request  = getRequestCallSign(`${actionPlus} ${url}`);
                    log.payload  = payload;
                    log.error    = errorLogEntry;
                    logEntry$.next(log);
                    return log;
                }
                else {
                    return errorLogEntry;
                }
            });
    }

    deleteRequest(actionPlus: string, token: string, url: string, payload: any, log?: LogEntry, fakeDelay = 0)
    {
        return this.httpClient.delete(url, { headers: this.getHeaders(token) })
            .pipe(delay(fakeDelay))
            .toPromise()
            .then((successLogEntry) =>
            {
                if (!!log) {
                    log.loggedIn = this.authService.isLoggedIn();
                    log.request  = getRequestCallSign(`${actionPlus} ${url}`);
                    log.payload  = payload;
                    log.response = successLogEntry;
                    logEntry$.next(log);
                    return log;
                }
                else {
                    return successLogEntry;
                }
            }, (errorLogEntry) =>
            {
                if (!!log) {
                    log.loggedIn = this.authService.isLoggedIn();
                    log.request  = getRequestCallSign(`${actionPlus} ${url}`);
                    log.payload  = payload;
                    log.error    = errorLogEntry;
                    logEntry$.next(log);
                    return log;
                }
                else {
                    return errorLogEntry;
                }
            });
    }

    getHeaders(token: string): HttpHeaders
    {
        return new HttpHeaders({
            'Authorization': `Bearer ${token}`,
        });
    }

    get recipeUrl(): string
    {
        const recipeUrl = this.configService.formatUrl('recipeUrl');
        return recipeUrl;
    }

    get createBrewEventUrl(): string
    {
        const createBrewEventUrl = this.configService.formatUrl('createBrewEventUrl');
        return createBrewEventUrl;
    }

    get brewEventQueryUrl(): string
    {
        const brewEventQueryUrl = this.configService.formatUrl('queryBrewEventUrl');
        return brewEventQueryUrl;
    }

    createBrewEvent(actionPlus: string, refreshToken: string, payload: any, log: LogEntry): Promise<any>
    {
        return this.postRequest(actionPlus, refreshToken, this.createBrewEventUrl, payload, log);
    }

    getHistory(actionPlus: string, refreshToken: string, payload: any, log: LogEntry): Promise<any>
    {
        return this.postRequest(actionPlus, refreshToken, this.brewEventQueryUrl, payload, log, 0);
    }

    // -----------------------------------------------------------------------------------------------------------------

    refreshRecipe(recipe: Recipe): Promise<LogEntry>
    {
        const url      = this.configService.formatUrl('recipeUrl');
        const logEntry = { payload: recipe } as LogEntry;
        return this.postRequest('POST(GetRecipe)', null, url, recipe, logEntry);
    }

    fireDeviceEvent(actionPlus: string, deviceEvent: any)
    {
        const url      = this.configService.formatUrl('deviceEventUrl');
        const logEntry = { payload: deviceEvent } as LogEntry;
        return this.postRequest(actionPlus, null, url, deviceEvent, logEntry);
    }

}
