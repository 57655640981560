import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Recipe, RecipeFilter } from '@nunc/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class RecipeService extends RestAspect
{

    constructor(
        http: HttpClient,
        configService: ConfigService,
        // todo [] make use of progress bar if appropriate
        //protected progressBarService: YukawaProgressBarService
    )
    {
        super(http, configService, configService.formatUrl('recipeUrl'));
    }

    queryRecipes(filter: RecipeFilter | any): Observable<QueryResult<Recipe>>
    {
        return this.query<Recipe>(this.formatServiceUrl('/query'), filter);
    }

}
