import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { NuncLibAuthService, refreshMe } from './nunc-lib.auth.service';


@Injectable({
    providedIn: 'root',
})
export class HeaderInterceptorService implements HttpInterceptor
{

    constructor(private authService: NuncLibAuthService)
    {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const refreshUrl = (req.url.indexOf('refresh') > -1);
        return from(!!refreshUrl ?
            this.authService.getRefreshToken() :
            this.authService.getAccessToken(),
        )
            .pipe(
                switchMap((token: any) =>
                    next.handle(req.clone({
                        setHeaders: {
                            authorization: `Bearer ${token || ''}`,
                        },
                    })),
                ),
                catchError((err: any) =>
                {
                    if (!!refreshUrl) {
                        this.authService.setAccessToken('');
                    }
                    else {
                        this.authService.setAccessToken(refreshMe);
                    }
                    return of() as Observable<HttpEvent<any>>;
                }),
            );

    }
}
