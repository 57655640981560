import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Label } from '@nunc/lib/domain';
import { ConfigService, RestAspect } from '@yukawa/chain-base-angular-client';
import { QueryResult } from '@yukawa/chain-base-angular-domain';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class LabelService extends RestAspect
{
    active: boolean = false;

    constructor(
        http: HttpClient,
        configService: ConfigService)
    {
        super(http, configService, configService.formatUrl('labelUrl'));
    }

    queryLabel(filter: any): Observable<QueryResult<any>>
    {
        return this.query<any>(this.formatServiceUrl('/query'), filter);
    }

    loadLabel(key: string): Observable<any>
    {
        return this.http.get<any>(`${this.formatServiceUrl()}/?key=${key}`);
    }

    deleteLabel(key: string): Observable<any>
    {
        return this.http.delete<any>(`${this.formatServiceUrl(`/${key}`)}`);
    }

    saveLabel(label: Label | any, method: 'put' | 'post' = 'post'): Observable<any>
    {
        return this.http[method]<any>(`${this.formatServiceUrl()}`, label);
    }

    updateLabel(label: Label | any): Observable<any>
    {
        return this.http.post<any>(`${this.formatServiceUrl()}/update`, label);
    }

    merge(label: Label | any): Observable<any>
    {
        return this.http.post(this.formatServiceUrl('/merge'), label);
    }

}
