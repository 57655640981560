import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoCoreModule } from '@nunc/lib/config';
import { YukawaSplashScreenService } from '@yukawa/chain-base-angular-comp/splash-screen';
import { AuthGuardService, AuthService, Session, SessionChangedEventArgs, SessionService } from '@yukawa/chain-main-angular-session';


const SIGN_IN_URL          = '/sign-in';
const DEFAULT_REDIRECT_URL = '/signed-in-redirect';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
})
export class AppComponent implements OnInit
{
    /**
     * Constructor
     */
    constructor(
        private readonly _authGuardService: AuthGuardService,
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _location: Location,
        private readonly _router: Router,
        private readonly _sessionService: SessionService,
        private readonly _splashScreenService: YukawaSplashScreenService,
        private readonly _translocoCoreModule: TranslocoCoreModule,
    )
    {
        _sessionService.sessionChanged.subscribe(this.sessionChanged, this);
        _sessionService.auth.loginRequest.subscribe(this.loginRequest, this);
    }

    public async ngOnInit(): Promise<void>
    {
        await this._translocoCoreModule.mergeTranslations((lang: string) => `yukawa/${lang}`);

        let command: string;
        if (await this._sessionService.restore()) {

            if (this._authGuardService.lastForbiddenRoute) {
                command = this._authGuardService.lastForbiddenRoute;
            }
            else {
                const locationPath = this._location.path(true);
                if (locationPath !== '' && locationPath !== '/') {
                    command = locationPath;
                }
                else {
                    command = DEFAULT_REDIRECT_URL;
                }
            }

        }

        await this._router.navigateByUrl(command);

        this._splashScreenService.hide();
        this._changeDetector.detectChanges();
    }

    private loginRequest(sender: AuthService): void
    {
        let redirectUrl: string;
        let queryParams = {};
        if (sender.isAuthenticated) {
            redirectUrl = '/signed-in-redirect';
        }
        else {
            if (this._authGuardService.lastForbiddenRoute) {
                queryParams = { redirectURL: this._authGuardService.lastForbiddenRoute };
            }

            redirectUrl = SIGN_IN_URL;
        }

        this._router.navigate([redirectUrl], { queryParams });
    }

    private async sessionChanged(sender: Session, ea: SessionChangedEventArgs): Promise<void>
    {
        if (ea.session instanceof Session.SessionDisconnected) {
            if (this._router.navigated) {
                const redirect = async (): Promise<boolean> => await this._router.navigate([SIGN_IN_URL]);

                if (ea.options?.redirectDelay) {
                    setTimeout(async () =>
                    {
                        await redirect();
                    }, ea.options?.redirectDelay);
                }
                else {
                    await redirect();
                }
            }
        }
    }
}
