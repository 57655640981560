import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NuncConfirmationService } from '@simulator/services/confirmation/confirmation.service';
import { NuncConfirmationDialogComponent } from '@simulator/services/confirmation/dialog/dialog.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        NuncConfirmationDialogComponent
    ],
    imports     : [
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        CommonModule
    ],
    providers   : [
        NuncConfirmationService
    ]
})
export class NuncConfirmationModule
{
    /**
     * Constructor
     */
    constructor(private _simulatorConfirmationService: NuncConfirmationService)
    {
    }
}
