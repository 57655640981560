import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

// Available compile-time from "dist" sister node_modules
// Available runtime from application node_modules
import { ConfigService } from '@yukawa/chain-base-angular-client';

import { ReplaySubject } from 'rxjs';
import { delay } from 'rxjs/operators';


export const configFactory = (configService: ConfigService): Promise<any> => new Promise((resolve) =>
{
    const locations: string [] = ['config.base.json', 'config.json'];
    configService.initConfigs(locations).then((cfg: any) =>
    {
        resolve(cfg);
    });
});

export const refreshMe = 'refresh-me';

@Injectable({
    providedIn: 'root',
})
export class NuncLibAuthService
{

    private loggedIn = false;
    private mAccessToken?: string;
    private mRefreshToken?: string;
    private mUserName?: string;

    constructor(
        private httpClient: HttpClient,
        private configService: ConfigService,
        private storage: Storage)
    {
        this.storage.create();
    }

    isLoggedIn(): boolean
    {
        return this.loggedIn;
    }

    get userName(): string | undefined
    {
        return this.mUserName;
    }

    set userName(name)
    {
        this.mUserName = name;
    }

    async login(form: any, log?: LogEntry, fakeDelay = 0): Promise<boolean | LogEntry>
    {
        const request = await this.getSessionUrl();
        return await this.requestSession(request, form, undefined, undefined, fakeDelay)
            .then((success: any) =>
            {
                this.setAccessToken(success?.access_token);
                this.setRefreshToken(success?.refresh_token);
                this.loggedIn = !!success;
                this.userName = form.username;
                if (!!log) {
                    log.loggedIn = this.loggedIn;
                    log.request  = getRequestCallSign(`POST(Login) ${request}`);
                    log.payload  = form;
                    log.response = success;
                    logEntry$.next(log);
                    return log;
                }
                else {
                    return this.loggedIn;
                }
            }, (error) =>
            {
                this.loggedIn = !error;
                if (!!log) {
                    log.loggedIn = this.loggedIn;
                    log.request  = getRequestCallSign(`POST(Login) ${request}`);
                    log.payload  = form;
                    log.response = error;
                    logEntry$.next(log);
                    return log;
                }
                else {
                    return this.loggedIn;
                }
            });
    }

    async loginWithToken(token?: string): Promise<boolean>
    {
        const request = await this.getSessionUrl(token);
        if (!!token) {
            if (token === refreshMe) {
                token = await this.getRefreshToken();
                if (!!token) {
                    return this.requestSession(request, undefined, token)
                        .then((success: any) =>
                        {
                            this.setAccessToken(success.access_token);
                            this.loggedIn = !!success;
                            return this.loggedIn;
                        }, (error) =>
                        {
                            this.loggedIn = !error;
                            return this.loggedIn;
                        });
                }
                else {
                    return Promise.reject(false);
                }
            }
        }
        else {
            return Promise.reject(false);
        }
        return Promise.resolve(true);
    }

    getAccessToken(): Promise<string | undefined>
    {
        let token: string | undefined;
        if (!this.accessToken) {
            return this.storage.get('accessToken')
                .then(accessToken =>
                {
                    this.mAccessToken = accessToken;
                    return accessToken;
                });
        }
        else {
            token = this.accessToken;
        }
        return Promise.resolve(token);
    }

    getRefreshToken(): Promise<string | undefined>
    {
        let token: string | null;
        if (!this.refreshToken) {
            return this.storage.get('refreshToken')
                .then((refreshToken: any) =>
                {
                    this.refreshToken = refreshToken;
                    return refreshToken;
                });
        }
        else {
            token = this.refreshToken;
        }
        return Promise.resolve(token);
    }

    setAccessToken(token: string)
    {
        this.accessToken = token;
        this.storage.set('accessToken', token);
    }

    get accessToken(): string | undefined
    {
        return this.mAccessToken;
    }

    set accessToken(token: string | undefined)
    {
        this.mAccessToken = token;
    }

    setRefreshToken(token: string)
    {
        this.refreshToken = token;
        this.storage.set('refreshToken', token);
    }

    get refreshToken(): string | undefined
    {
        return this.mRefreshToken;
    }

    set refreshToken(token: string | undefined)
    {
        this.mRefreshToken = token;
    }

    async requestSession(sessionUrl: string, body?: any, token?: string, abort?: boolean, fakeDelay = 0): Promise<any>
    {
        if (!!token) {
            if (!!abort) {
                return this.httpClient.delete(`${sessionUrl}`)
                    .pipe(
                        delay(fakeDelay),
                    )
                    .toPromise();
            }
            else {
                return this.httpClient.get(`${sessionUrl}`)
                    .pipe(
                        delay(fakeDelay),
                    )
                    .toPromise();
            }
        }
        else {
            return await this.httpClient.post(`${sessionUrl}`, body)
                .pipe(
                    delay(fakeDelay),
                )
                .toPromise();
        }

    }

    getSessionUrl(token?: string | undefined): Promise<string>
    {
        let sessionUrl: string;
        if (!this.configService.config.refreshUrl) {
            return configFactory(this.configService)
                .then(() =>
                {
                    if (!!token) {
                        sessionUrl = this.configService.formatUrl('refreshUrl');
                    }
                    else {
                        sessionUrl = this.configService.formatUrl('loginUrl');
                    }
                    return sessionUrl;
                });
        }
        else {
            if (!!token) {
                sessionUrl = this.configService.formatUrl('refreshUrl');
            }
            else {
                sessionUrl = this.configService.formatUrl('loginUrl');
            }
            return Promise.resolve(sessionUrl);
        }
    }

    async abortSession(log?: LogEntry): Promise<boolean | LogEntry>
    {
        const token      = await this.getAccessToken();
        const abort      = true;
        const requestUrl = await this.getSessionUrl(token);
        const emptyToken = '';
        return this.requestSession(requestUrl, undefined, token, abort)
            .then((success: any) =>
            {
                this.setAccessToken(emptyToken);
                if (!!log) {
                    this.loggedIn = false;
                    log.loggedIn  = this.loggedIn;
                    log.request   = getRequestCallSign(`DELETE(Logout) ${requestUrl}`);
                    log.payload   = {};
                    log.response  = success;
                    logEntry$.next(log);
                    return log;
                }
                return true;
            }, (error: any) =>
            {
                if (!!log) {
                    log.loggedIn = this.loggedIn;
                    log.request  = getRequestCallSign(`DELETE(Logout) ${requestUrl}`);
                    log.payload  = {};
                    log.response = error;
                    logEntry$.next(log);
                    return log;
                }
                return false;
            });
    }

}

export interface LogEntry
{
    operation?: string,
    timestamp?: Date,
    took?: number,
    url?: string,
    path?: string,
    method?: string,
    payload: any;
    request?: any;
    loggedIn?: boolean;
    response?: any;
    error?: any;
}

export const getRequestCallSign = (request: string): string =>
{
    // regEx matches 1. HTTP Action eg POST and 2. last 3 segments of augmented action+request
    // to produce something like: "POST(Login)@15:09:32 /security-service/auth/token"
    const regEx                            = /(?:[A-Z]*\([a-zA-Z]*\) |(\/[a-zA-Z|-]*){3}\?)/g;
    const matches: RegExpMatchArray | null = request.match(regEx);
    const timenow                          = new Date().toTimeString().substr(0, 8);
    let callSign                           = '';
    if (!!matches) {
        callSign = `${matches[0]?.trim()}@${timenow} ${matches[1]?.substr(0, matches[1]?.length - 1)}`;
    }
    return callSign;
};

export const logEntry$ = new ReplaySubject<LogEntry>({} as any);
