/* tslint:disable:max-line-length */
import { NuncNavigationItem } from '@simulator/components/navigation';

export const defaultNavigation: NuncNavigationItem[] = [
    
];
export const compactNavigation: NuncNavigationItem[] = [
    
];
export const futuristicNavigation: NuncNavigationItem[] = [
   
];
export const horizontalNavigation: NuncNavigationItem[] = [
   
];
