import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NuncScrollbarModule } from '@simulator/directives/scrollbar/public-api';
import { NuncHorizontalNavigationBasicItemComponent } from '@simulator/components/navigation/horizontal/components/basic/basic.component';
import { NuncHorizontalNavigationBranchItemComponent } from '@simulator/components/navigation/horizontal/components/branch/branch.component';
import { NuncHorizontalNavigationDividerItemComponent } from '@simulator/components/navigation/horizontal/components/divider/divider.component';
import { NuncHorizontalNavigationSpacerItemComponent } from '@simulator/components/navigation/horizontal/components/spacer/spacer.component';
import { NuncHorizontalNavigationComponent } from '@simulator/components/navigation/horizontal/horizontal.component';
import { NuncVerticalNavigationAsideItemComponent } from '@simulator/components/navigation/vertical/components/aside/aside.component';
import { NuncVerticalNavigationBasicItemComponent } from '@simulator/components/navigation/vertical/components/basic/basic.component';
import { NuncVerticalNavigationCollapsableItemComponent } from '@simulator/components/navigation/vertical/components/collapsable/collapsable.component';
import { NuncVerticalNavigationDividerItemComponent } from '@simulator/components/navigation/vertical/components/divider/divider.component';
import { NuncVerticalNavigationGroupItemComponent } from '@simulator/components/navigation/vertical/components/group/group.component';
import { NuncVerticalNavigationSpacerItemComponent } from '@simulator/components/navigation/vertical/components/spacer/spacer.component';
import { NuncVerticalNavigationComponent } from '@simulator/components/navigation/vertical/vertical.component';

@NgModule({
    declarations: [
        NuncHorizontalNavigationBasicItemComponent,
        NuncHorizontalNavigationBranchItemComponent,
        NuncHorizontalNavigationDividerItemComponent,
        NuncHorizontalNavigationSpacerItemComponent,
        NuncHorizontalNavigationComponent,
        NuncVerticalNavigationAsideItemComponent,
        NuncVerticalNavigationBasicItemComponent,
        NuncVerticalNavigationCollapsableItemComponent,
        NuncVerticalNavigationDividerItemComponent,
        NuncVerticalNavigationGroupItemComponent,
        NuncVerticalNavigationSpacerItemComponent,
        NuncVerticalNavigationComponent
    ],
    imports     : [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        NuncScrollbarModule
    ],
    exports     : [
        NuncHorizontalNavigationComponent,
        NuncVerticalNavigationComponent
    ]
})
export class NuncNavigationModule
{
}
