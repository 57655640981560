import { NgModule } from '@angular/core';
import { NuncUtilsService } from '@simulator/services/utils/utils.service';

@NgModule({
    providers: [
        NuncUtilsService
    ]
})
export class NuncUtilsModule
{
    /**
     * Constructor
     */
    constructor(private _simulatorUtilsService: NuncUtilsService)
    {
    }
}
