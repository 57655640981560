import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslocoCoreModule } from '@nunc/lib/config';
import { ProfileModule } from '@nunc/lib/profile';
import { getUserLanguage } from '@nunc/lib/shared';
import { APP_ENVIRONMENT_TOKEN, InitModule, setEnvironment } from '@yukawa/chain-base-angular-client';
import { YukawaAlertModule } from '@yukawa/chain-base-angular-comp/alert';
import { YukawaConsoleLogAlertsModule } from '@yukawa/chain-base-angular-comp/console-log-alerts';
import { YukawaSplashScreenModule } from '@yukawa/chain-base-angular-comp/splash-screen';
import { SessionModule } from '@yukawa/chain-main-angular-session';
import { IconsModule } from 'app/core/icons/icons.module';
import { environment } from 'environments';


setEnvironment(environment);

const availableLanguages = [
    {
        id   : 'en',
        label: 'English',
    },
];

@NgModule({
    imports  : [
        IconsModule,
        InitModule,
        ProfileModule,
        SessionModule,
        TranslocoCoreModule.forRoot({
            availableLangs      : availableLanguages,
            defaultLang         : getUserLanguage(availableLanguages.map(lang => lang.id), 'en'),
            fallbackLang        : 'en',
            reRenderOnLangChange: true,
            prodMode            : environment.production,
        }),
        YukawaAlertModule.forRoot({
            primary: 'heroicons_solid:check-circle',
            accent : 'heroicons_solid:check-circle',
            warn   : 'heroicons_solid:x-circle',
            basic  : 'heroicons_solid:check-circle',
            info   : 'heroicons_solid:information-circle',
            success: 'heroicons_solid:check-circle',
            warning: 'heroicons_solid:exclamation',
            error  : 'heroicons_solid:x-circle',
            dismiss: 'heroicons_solid:x',
        }),
        YukawaConsoleLogAlertsModule.forRoot(
            !environment.production,
            'outline',
            ...environment.logTypes,
        ),
        YukawaSplashScreenModule.forRoot(true),
    ],
    providers: [
        {
            provide : APP_ENVIRONMENT_TOKEN,
            useValue: environment,
        },
    ],
})
export class CoreModule
{
    /**
     * Constructor
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: CoreModule,
    )
    {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
